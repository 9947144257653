// 统一封装请求方式模块
import service from './request' //引入我们二次封装的axios.js文件
/**
 * method: 请求方式
 * @param url 请求地址
 * @param params 请求参数
 */
const api = {
    get(url, params) {
        let config = {
            method: 'get',
            url: url,
        }
        if (params) {
            config.url = config.url + '?'
            config.data = params
            for(let key in params){
                if(params[key] || params[key] ==0 || params[key] =='0' ){
                    config.url = config.url + `${key}=${params[key]}&`
                }
            }
        }
        return service(config)
    },
    post(url, params) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) {
            config.data = params
        }
        return service(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) {
            config.data = params
        }
        return service(config)
    },
    del(url, params) {
        const config = {
            method: 'delete',
            url: url
        }
        if (params) {
            config.data = params
        }
        return service(config)
    },
    down(url, params){
        let config = {
            method: 'get',
            url: url,
            headers:{
                download: 'exe',
                "content-Type":"application/vnd.ms-excel"
            },
            responseType: 'arraybuffer',
        }
        if (params) {
            config.url = config.url + '?'
            config.data = params
            for(let key in params){
                config.url = config.url + `${key}=${params[key]}&`
            }
        }
        return service(config)
    },
    upFile(url,params){
        const formData = new FormData();
        formData.append('file', params.file);
        console.log('formData',formData)
        let config = {
            method: 'post',
            url: url,
            data: formData,
            headers:{
                'Content-Type': 'multipart/form-data',
            },
        }
        return service(config)
    }
}
export default api