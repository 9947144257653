import api from '../httpServer'
import config from '../config'
import store from '@/store/index'

const logins ={
    login(data) {
        return api.get(`${config.headerUrl}login/Staff`,data)
    },
    logout() {
        // 退出 清空vuex中的信息存在
        store.dispatch('common/setLoginUserInfo',{})
    }
}

export default logins